<template>
  <v-card v-if="task" min-height="600px">
    <v-card-actions>
      <v-btn-toggle
        mandatory
        v-model="mode"
      >
        <v-btn x-large outlined value="weight">Weight</v-btn>
        <v-btn x-large outlined value="qa">QA</v-btn>
        <v-btn x-large outlined value="waste">Waste</v-btn>
      </v-btn-toggle>
      <v-spacer/>
      <v-btn x-large outlined @click="$emit('close')">Close</v-btn>
    </v-card-actions>
    <v-card-title>
      {{ task.title }}
      <v-chip class="mx-2" color="primary" v-for="member of teamMembers" v-bind:key="member">
        {{ member }}
      </v-chip>
      <v-spacer/>
      {{ formatWeightWithUnits(totalWeight) }}
    </v-card-title>
    <v-card-title class="py-0 my-0">
      <template v-if="netWeightOfComponentMeasurement">
        <v-spacer/>
        <v-chip color="red" outlined v-if="isShortWeightTask">
          SHORT {{ formatWeightWithUnits(Math.abs(weightDifference)) }}
        </v-chip>
        <v-chip color="blue" outlined v-if="isOverWeightTask">
          OVER {{ formatWeightWithUnits(Math.abs(weightDifference)) }}
        </v-chip>
        <v-chip color="green" outlined v-if="isWeightOkTask">OK</v-chip>
        <v-spacer/>
        <v-icon color="green">mdi-scale</v-icon>
        {{ formatWeightWithUnits(netWeightOfComponentMeasurement) }}
      </template>
    </v-card-title>
    <!--    <v-card-subtitle>{{ task.description }}</v-card-subtitle>-->
    <v-card-text v-if="mode==='qa'">
      <v-card class="mt-2">
        <v-card-text>
          <v-form
            v-model="isValidQA"
            ref="qaForm"
            lazy-validation
          >
            <p>
              If the quality is good check "Mark as Pass" otherwise enter a note to record a quality issue.
            </p>
            <v-checkbox
              label="Mark as Pass"
              v-model="qaCheck"
              @change="saveQa"
            />

            <v-textarea
              :label="qaCheck?'No entry required, text will not be saved' :'Failed.  Enter reason'"
              :disabled="qaCheck"
              v-model="qaFailureReason"
              @change="saveQa"
            />

            <p>Click Close to Save</p>
          </v-form>
        </v-card-text>
      </v-card>

    </v-card-text>
    <v-card-text v-if="mode==='weight' || mode==='waste'">
      <v-form
        v-model="isValid"
        ref="form"
        lazy-validation
      >
        <v-card v-for="(meal,index) of meals" v-bind:key="`M${index}`" class="my-2">
          <v-card-text class="font-weight-bold">
            <v-chip large outlined>{{ formatWeightWithUnits(meal.totalAmount - meal.totalAmountAllergy) }}</v-chip>
            {{ extractMealDescription(meal.meal_id) }}
            <br/>

          </v-card-text>
          <v-card-text class="">
            <v-row>
              <v-col class="py-2 my-0">
                Main Line {{
                  formatWeightWithUnits(
                    getTotalAllSizes(task.source.component, task.source.counts[meal.meal_id].count, task.source.counts[meal.meal_id].portionSize)
                    -
                    getTotalAllSizes(task.source.component, task.source.counts[meal.meal_id].allergyCount, task.source.counts[meal.meal_id].portionSize)
                  )
                }} (per recipe)
              </v-col>
              <v-col class="pa-0 ma-0">
                <v-chip outlined v-for="(count,size) of sortBySize(task.source.counts[meal.meal_id].count)"
                        v-bind:key="`count_m_${size}`">
                  {{ size[0].toUpperCase() }} {{
                    formatWeightWithUnits(getPortionSize(task.source.component, size, task.source.counts[meal.meal_id].portionSize))
                  }} ({{ count }})
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 my-0">
                Allergy Line {{
                  formatWeightWithUnits(getTotalAllSizes(task.source.component, task.source.counts[meal.meal_id].allergyCount, task.source.counts[meal.meal_id].portionSize) - meal.totalAmountAllergy)
                }} (per recipe),
                {{ formatWeightWithUnits(meal.totalAmountAllergy) }} (sub replacement)
              </v-col>
              <v-col class="py-0 my-0">
                <v-chip class="mr-2" outlined
                        v-for="(count,size) of sortBySize(task.source.counts[meal.meal_id].allergyCount)"
                        v-bind:key="`count_m_${size}`">
                  {{ size[0].toUpperCase() }} {{
                    formatWeightWithUnits(getPortionSize(task.source.component, size, task.source.counts[meal.meal_id].portionSize))
                  }} ({{ count }})
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-center">
            <v-row>
              <v-col></v-col>
              <v-col>
                <v-btn-toggle rounded v-model="containerFilter">
                  <v-btn small value="\*">Popular (*)</v-btn>
                  <v-btn small value="hotel pan">Hotel Pan</v-btn>
                  <v-btn small value="^(?!.*hotel pan).*">Other Container</v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col>
                <router-link :to="{name:'Container'}">edit containers</router-link>
              </v-col>
            </v-row>
            <v-chip-group
              column
              active-class="deep-purple--text darken-3 text--accent-4"
            >
              <v-chip
                v-for="container of selectedContainers" v-bind:key="container.id"
                outlined
                @click="addContainerMeasurement(container.id, meal.meal_id)"
              >
                  {{
                    container.name
                  }}
              </v-chip>
            </v-chip-group>
<!--            <v-row>-->
<!--              <v-col cols="3" v-for="container of containers.filter(c => new RegExp(containerFilter,'i').test(c.name))"-->
<!--                     v-bind:key="container.id">-->
<!--                <v-btn outlined small @click="addContainerMeasurement(container.id, meal.meal_id)">{{-->
<!--                    container.name-->
<!--                  }}-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </v-card-text>
          <!--          <v-card-actions>-->
          <!--            <v-autocomplete-->
          <!--              label="Add Container"-->
          <!--              v-model="container"-->
          <!--              :items="containers"-->
          <!--              item-text="name"-->
          <!--              item-value="id"-->
          <!--              return-object-->
          <!--              @change="container && addContainerMeasurement(container.id, meal.meal_id)"-->
          <!--            />-->
          <!--            <v-btn text-->
          <!--                   outlined-->
          <!--                   class="ml-4"-->
          <!--                   :disabled="!container"-->
          <!--                   @click="addContainerMeasurement(container.id, meal.meal_id)">Add Another-->
          <!--            </v-btn>-->
          <!--            <v-spacer/>-->
          <!--            <router-link :to="{name:'Container'}">edit containers</router-link>-->
          <!--          </v-card-actions>-->
          <v-card-text>

            <!--                  <pre>      {{ {mealId: meal.meal_id, cmid: containerMeasurements[meal.meal_id], measurements, containerMeasurements} }}        </pre>-->
            <v-row>
              <v-col align-self="end" cols="">
                <v-data-table
                  v-if="activeContainerMeasurements[meal.meal_id]"
                  :headers="containerHeaders"
                  :items="activeContainerMeasurements[meal.meal_id].component_measurement_detail"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.container_id="{item}">
                    {{ getContainer(item.container_id).name }} ({{
                      formatWeightWithUnits(getContainer(item.container_id).weight)
                    }})
                  </template>
                  <template v-slot:item.action="{item,index}">
                    <v-icon @click="removeContainerMeasurement(meal.meal_id, item, index)" class="ml-2" color="red">
                      mdi-minus-circle
                    </v-icon>
                  </template>
                  <template v-slot:item.total_weight="{item}">
                    <v-text-field
                      style="max-width:150px"
                      dense
                      v-model="item.total_weight"
                      prefix="g"
                      reverse
                      type="number"
                      min="0"
                      :rules="weightRules(item)"
                    />
                  </template>
                  <template v-slot:item.net_weight="{item}">
                    <v-text-field
                      disabled
                      style="max-width:150px"
                      dense
                      type="number"
                      min="0"
                      prefix="g"
                      reverse
                      :value="getNetWeight(item.container_id,item.total_weight)"
                    />
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="2" align-self="end">
                <v-btn
                  class="primary"
                  @click="save(meal)"
                >
                  <span>Save</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-alert type="warning" v-if="percentOver(meal)>10 && (activeTotal(meal)-totalWeight>1000)">
              WARNING: You have entered {{ percentOver(meal) }}% over the <b>total</b> target weight! Are you sure this
              is correct?
              <br/>
              You entered: {{ formatWeightWithUnits(activeTotal(meal)) }} vs total component target
              {{ formatWeightWithUnits(totalWeight) }}
            </v-alert>

            Note: select both a container and enter the total weight (including the container)
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>

</template>

<script>
import {mapActions, mapState} from "vuex";
import {formatWeightWithUnits} from "@/store/utils";

export default {
  name: "ComponentWeighTask",
  props: {
    task: {type: Object, default: null, required: false},
    netWeightOfComponentMeasurement: {type: Number, default: null, required: false},
    isShortWeightTask: {type: Boolean, default: null, required: false},
    weightDifference: {type: Number, default: null, required: false},
    totalWeight: {type: Number, default: null, required: false},
    isOverWeightTask: {type: Boolean, default: null, required: false},
    isWeightOkTask: {type: Boolean, default: null, required: false},
    measurements: {type: Object, default: null, required: false},
    wasteMeasurements: {type: Object, default: null, required: false},
    line: {type: String, default: null, required: false},
    mealId: {type: String, default: null, required: false},
  },
  data() {
    return {
      // container_id: {},
      // total_weight: {},
      isSaved: {},
      containerMeasurements: {},
      containerWasteMeasurements: {},
      containerHeaders: [
        {value: 'container_id', text: 'Container'},
        {value: 'total_weight', text: 'Total Weight'},
        {value: 'net_weight', text: 'Net'},
        {value: 'event', text: 'Step'},
        {value: 'action'}
      ],
      container: null,
      weightGreaterThanContainer: v => {
        return v || true;
      },
      isValid: false,
      mode: 'weight',
      isValidQA: false,
      qaCheck: null,
      qaFailureReason: null,
      containerFilter: '\\*', //'hotel pan'
    }
  },

  mounted() {
    this.resetValues();
    return this.fetchContainers();
  },
  watch: {
    'task.source.meals': 'resetValues',
    'task.source.qa.passed'(v) {
      this.qaCheck = v;
    },
    'task.source.qa.failureReason'(v) {
      this.qaFailureReason = v;
    }
  },
  computed: {
    ...mapState(['containers']),
    meals() {
      const meals = this.task && this.task.source ? this.task.source.meals : [];
      if (this.mealId) {
        console.log('filtering')
        return meals.filter(m => m.meal_id == this.mealId);
      } else {
        return meals
          .sort((m2, m1) => m1.totalAmount - m2.totalAmount);
      }
    },
    teamMembers() {
      return [...new Set((this.task.execution || []).flatMap(e => e.team_members.map(m => m.name)))];
    },
    activeContainerMeasurements() {
      if (this.mode === 'weight') {
        return this.containerMeasurements;
      } else {
        return this.containerWasteMeasurements;
      }
    },
    activeTotal() {
      return (meal) => {
        const activeContainerMeasurement = this
          .activeContainerMeasurements[meal.meal_id];
        if (!activeContainerMeasurement) {
          return 0;
        } else {
          return activeContainerMeasurement
            .component_measurement_detail
            .map(detail => Number(detail.total_weight) + Number(detail.container ? detail.container.weight : 0))
            .reduce((sum, i) => sum + i, 0);
        }
      };
    },
    percentOver() {
      return (meal) => (((this.activeTotal(meal) / this.totalWeight) - 1) * 100).toFixed(0);
    },
    selectedContainers() {
      return this.containers.filter(c => new RegExp(this.containerFilter,'i').test(c.name))
    }
  },
  methods: {
    ...mapActions(['fetchContainers']),
    formatWeightWithUnits,
    resetValues() {
      console.log('resetting', this.task.source);
      this.mode = 'weight';
      this.qaCheck = this.task.source.qa && this.task.source.qa.passed;
      this.qaFailureReason = this.task.source.qa && this.task.source.qa.failureReason;
      for (const meal of this.meals) {
        const {id, meal_id, measurements = []} = meal;
        console.log('reset', {id, meal_id, measurements});
        this.$set(this.isSaved, id, false);
      }
      this.containerMeasurements = this.measurements;
      this.containerWasteMeasurements = this.wasteMeasurements;

      console.log('measurements', this.measurements);
      console.log('waste measurements', this.wasteMeasurements);
    },
    validate() {
      const validate = this.$refs.form.validate();
      console.log('validate', validate);
      return validate;
    },
    save(meal) {
      const measurements = this.mode === 'weight' ? this.containerMeasurements : this.containerWasteMeasurements;
      const component_measurement = measurements[meal.meal_id];
      const values = ({
        task: this.task,
        mealUpdate: {...meal, component_measurement}
      })
      if (!this.validate()) {
        console.log('validate fail');
        return;
      } else {
        console.log('validate ok');
      }
      if (this.mode === 'weight') {
        values.event = 'file';
      }
      if (this.mode === 'waste') {
        values.event = 'waste'
      }
      const {id, component_measurement: {component_measurement_detail}} = values.mealUpdate;
      // need to sum all component_measurement_detail
      const netWeight = component_measurement_detail.reduce((sum, i) => this.getNetWeight(i.container_id, i.total_weight) + sum, 0);
      const containerTotal = component_measurement_detail.reduce((sum, i) => this.getContainer(i.container_id).weight + sum, 0);
      if (netWeight < 0) {
        alert(`The total weight is less than the weight of the containers (${containerTotal}g)`);
        return;
      }
      this.$emit('save', values);
      this.$set(this.isSaved, id, true);

      if (Object.values(this.isSaved).every(b => !!b)) {
        // if (this.meals.length === 1) {
        console.log('closing dialog only single weight')
        this.$emit('close');
      } else {
        console.log('keeping dialog open as >1 weight')
      }
      // this.container_id = {};
      // this.total_weight = {};
    },
    extractMealDescription(id) {
      const mealDescription = this.task.description.split('\n').find(s => s.indexOf(`M${id}`) > -1);
      return mealDescription ? mealDescription : `M${id}`;
    },
    getContainer(container_id) {
      // const container_id = this.container_id[id];
      // console.log('get container', {container_id, containers: this.containers});
      return this.containers.find(c => c.id === container_id) || {};
    },
    getNetWeight(container_id, total_weight) {
      const container = this.getContainer(container_id);
      const weight = total_weight;
      if (container && weight !== undefined) {
        return weight - container.weight;
      } else {
        // console.log('cannot compute net weight', {id, weight, container});
      }
    },
    addContainerMeasurement(container_id, mealId) {
      const eventTypes = {
        weight: 'file',
        waste: 'waste'
      }
      const event = eventTypes[this.mode];
      console.log(`adding ${this.mode} container measurement`, {container_id, mealId, event});
      const measurements = this.mode === 'weight' ? this.containerMeasurements : this.containerWasteMeasurements;
      measurements[mealId] = measurements[mealId] || {component_measurement_detail: []};
      measurements[mealId].component_measurement_detail.push({
        container_id,
        total_weight: 0,
        event
      })
    },
    removeContainerMeasurement(mealId, item, index) {
      // console.log('remove line', item);

      const measurements = this.mode === 'weight' ? this.containerMeasurements : this.containerWasteMeasurements;
      const removeMeasurement = measurements[mealId][index];
      if (item.id) {
        // remove on server too
        // console.log('container measurement detail id to remove', item.id);
      }

      // console.log('index', index);
      const componentMeasurementDetail = measurements[mealId].component_measurement_detail;
      // console.log('before', componentMeasurementDetail);
      componentMeasurementDetail.splice(index, 1);
      // console.log('after', componentMeasurementDetail);
      this.$emit('remove', {task: this.task, removeMeasurement})

    },
    saveQa() {
      const {qaCheck, qaFailureReason} = this;
      const failureReason = qaCheck ? null : qaFailureReason;
      this.$emit('saveqa', {task: this.task, qa: {passed: !!qaCheck, failureReason: failureReason}});
    },
    getTotalAllSizes(component, counts, size) {
      const {small_ratio = 0.75, large_ratio = 1.25} = component;
      const smallCount = counts.small || 0;
      const largeCount = counts.large || 0;
      const mediumCount = counts.medium || 0;
      if (!component.monosized) {
        const smallAmount = smallCount * (size * small_ratio);
        const largeAmount = largeCount * (size * large_ratio);
        const mediumAmount = mediumCount * (size);
        return smallAmount + largeAmount + mediumAmount;
      } else {
        return size * (smallCount + mediumCount + largeCount);
      }
    },
    sortBySize(map) {
      const key = {
        small: 0,
        medium: 1,
        large: 2
      }
      return Object.fromEntries(
        Object.entries(map)
          .sort((a, b) => key[a[0]] - key[b[0]])
      )
    },
    getPortionSize(component, size, amount) {
      const {small_ratio = 0.75, large_ratio = 1.25} = component;
      if (!component.monosized) {
        if (size === 'small') {
          return small_ratio * amount;
        }
        if (size === 'large') {
          return large_ratio * amount;
        }
      }
      return amount;
    },
    weightRules(item) {
      return [
        v => this.getContainer(item.container_id).weight < v || 'weight must be greater than container'
      ];
    }
  }
}
</script>

<style scoped>

</style>