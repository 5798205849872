<template>
  <WeighTasks
      team="components"
      :team-filter="['veg','protein','starch','sauciers','misc']"
  />

</template>

<script>


import WeighTasks from "@/components/tasks/ComponentWeighTasks";
export default {
  name: "ComponentWeighTasks",
  components: {WeighTasks},
}
</script>

<style scoped>

</style>
