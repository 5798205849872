<template>
  <v-container fluid>
    <v-alert type="error" outlined>
      This page should no longer be used!
      <br/>
      Instead, you should use
      <ul>
        <li>
          <router-link :to="{name:'ComponentAudit'}">ComponentAudit</router-link>
          (for component weight measurements)
        </li>
        <li>
          <router-link :to="{name:'ComponentWaste'}">ComponentWaste</router-link>
          (for component waste recording)
        </li>
      </ul>
    </v-alert>
    <!--    {{ this.componentMeasurements }}-->
    <v-dialog
      v-model="showWeighDialog"
      width="100%"
      persistent
    >
      <ComponentWeighTask
        v-if="selectedTask"
        :measurements="componentMeasurements[getComponentId(selectedTask)]"
        :waste-measurements="componentWasteMeasurements[getComponentId(selectedTask)]"
        :task="selectedTask"
        :total-weight="getTotalAmount(selectedTask)"
        :is-over-weight-task="isOverWeightTask(selectedTask)"
        :is-short-weight-task="isShortWeightTask(selectedTask)"
        :is-weight-ok-task="isWeightOkTask(selectedTask)"
        :net-weight-of-component-measurement="getNetWeightOfComponentMeasurement(selectedTask)"
        :weight-difference="getWeightDifference(selectedTask)"
        v-on:save="saveWeight"
        v-on:saveqa="saveQA"
        v-on:close="closeDialog"
        :line="line"
        :meal-id="mealId"
      />
    </v-dialog>
    <v-dialog v-model="showContainerStatDialog" max-width="600">
      <v-card>
        <v-card-title>
          Containers that were used in Measurements
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Component Weight Measurements
              <li v-for="container of activeContainers" v-bind:key="container.id">
                {{ container.name }} : {{ container.count }}
              </li>
            </v-col>
            <v-col>
              Waste Measurements
              <li v-for="container of activeContainersWaste" v-bind:key="container.id">
                {{ container.name }} : {{ container.count }}
              </li>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-toolbar flat class="pb-10">
      <!--      {{ {searchInput, search, showWeighDialog} }}-->
      <template v-if="!scannerMode">
        <v-text-field
          class="d-print-none"
          v-model="searchInput"
          append-icon="mdi-magnify"
          :label="scannerMode?'Scanner Mode is Enabled':'Search'"
          single-line
          hide-details
          clearable
          :disabled="scannerMode"
        />
        <v-spacer/>
        <v-btn-toggle
          v-model="search"
        >
          <v-btn
            v-for="team of teamFilter"
            v-bind:key="team"
            :value="`team:${team}`"
          >{{ team }}
          </v-btn>
        </v-btn-toggle>
        <v-spacer/>
      </template>
      <v-text-field :value="datesFormatted"
                    persistent-hint
                    hint="Meal Dates"
                    label="Select dates"
                    single-line
                    readonly
                    @click="datePickerDate=dates[0]; showDatePicker=true"
                    append-icon="mdi-calendar"
      />
      <v-dialog
        v-model="showDatePicker"
        ref="dialog"
        width="290px"
        persistent
      >
        <v-date-picker
          v-model="datePickerDate"
          no-title
          :allowed-dates="allowedDates"
          @close="closeDatePicker"
        ></v-date-picker>
        <v-btn @click="closeDatePicker">Close</v-btn>
      </v-dialog>
      <v-checkbox
        v-model="scannerMode"
        label="Scanner"
        class="ml-1"
      />
    </v-toolbar>
    <template v-if="!scannerMode">
      <v-toolbar flat>
        <v-checkbox
          v-model="showAllergy"
          label="Show Allergy"
          class="mr-10"
        />
        <v-checkbox
          v-model="showGarnish"
          label="Show Garnish"
          class="mr-10"
        />
        <v-checkbox
          v-model="automaticShow"
          label="Auto Popup (when 1 result)"
        />
        <v-spacer/>
        <v-checkbox
          label="export meals"
          v-model="exportMealTasks"
        />
        <v-btn class="pb-5" icon @click="showContainerStatDialog=true">
          <v-icon>mdi-package</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar flat>
        <v-spacer/>
        <v-btn-toggle v-model="taskFilter" mandatory>
          <v-btn value="all">All {{ showCount(tasks) }}</v-btn>
          <v-btn value="no-weight">Not Weighed{{ showCount(tasksWithoutMeasurements) }}</v-btn>
          <v-btn value="has-weight">Weighed{{ showCount(tasksWithMeasurements) }}</v-btn>
          <v-btn value="ok">Ok{{ showCount(tasksOk) }}</v-btn>
          <v-btn value="short">Short{{ showCount(tasksShort) }}</v-btn>
          <v-btn value="over">Over{{ showCount(tasksOver) }}</v-btn>
          <v-btn value="qa-fail">QA Fail{{ showCount(tasksWithQaFail) }}</v-btn>
          <v-btn value="qa-missing">QA Missing{{ showCount(tasksWithoutQA) }}</v-btn>
          <v-btn value="has-waste">Waste{{ showCount(tasksWithWaste) }}</v-btn>
        </v-btn-toggle>
        <v-spacer/>
      </v-toolbar>
    </template>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-alert type="info" v-if="scannerMode && !loading">
      Scan a label
    </v-alert>

    <TaskPanel
      v-show="!scannerMode"
      v-if="hasDates && !loading"
      :team="team"
      :tasks-in="tasksFiltered"
      view="all"
      :search="search"
      :card-clicked-handler="cardClickedHandler"
      create-task-button-hidden
      hide-task-status-filter
      :filter="filter"
      :data-as-table-override="dataAsTable"
      :export-file="`component-weigh-${this.taskFilter}-${this.dateFrom}-${this.dateTo}.csv`"
      v-on:update:tasks="updateTasks"
    >
      <template v-slot:task-title="{ props: { task}}">
        <v-card-title>
          {{ task.title }}
          <v-spacer/>
          {{ formatWeightWithUnits(getTotalAmount(task)) }}
          <v-icon v-if="task.complete">mdi-check</v-icon>
        </v-card-title>
      </template>
      <!--      /* eslint-disable-next-line vue/no-unused-vars */-->
      <template v-slot:task-text="{ props: {task}}">
        <div v-if="!showAllergy"></div>
        <v-row v-if="showAllergy && getActiveAllergiesInMeals(task).length>0" class="px-5">
          <v-col>
            Allergies:
            <v-chip x-small v-for="a of getActiveAllergiesInMeals(task)" v-bind:key="a" class="mr-1">
              {{ getRestrictionText(a) }}
            </v-chip>
          </v-col>
          <v-col cols="3" class="text-right">
            {{ formatWeightWithUnits(getTotalAmountofAllergiesInMeals(task)) }}
          </v-col>
        </v-row>

      </template>
      <!--      <template v-slot:task-content="{ props: { }}">-->
      <!--        <span></span>-->
      <!--      </template>-->
      <template v-slot:task-content="{ props: { task}}">
        <v-card-text class="py-0 my-0" v-if="getNetWeightOfComponentMeasurement(task)>0">
          <!--          <span v-if="getWeightDifference(task) < 0">Short</span>-->
          <!--          <span v-if="getWeightDifference(task) > 0">Over</span>-->
          <!--          <v-spacer/>-->
          <!--          {{ formatWeightWithUnits(Math.abs(getWeightDifference(task))) }}-->
          <v-row dense>
            <v-col class="text-center">
              <v-chip-group>
                <v-chip color="red" outlined v-if="isShortWeightTask(task)">
                  {{ getWeightDifferencePercent(task) }} SHORT {{
                    formatWeightWithUnits(Math.abs(getWeightDifference(task)))
                  }}
                </v-chip>
                <v-chip color="blue" outlined v-if="isOverWeightTask(task)">
                  {{ getWeightDifferencePercent(task) }} OVER {{
                    formatWeightWithUnits(Math.abs(getWeightDifference(task)))
                  }}
                </v-chip>
                <v-chip color="green" outlined v-if="isWeightOkTask(task)">OK</v-chip>
              </v-chip-group>
            </v-col>
            <v-col class="text-right">
              <v-icon small color="green">mdi-scale</v-icon>
              {{ formatWeightWithUnits(getNetWeightOfComponentMeasurement(task)) }}
            </v-col>
          </v-row>
          <v-row dense v-if="getNetWeightOfComponentMeasurementWaste(task)>0">
            <v-col>
              <v-chip-group>
                <v-chip color="yellow">
                  {{ getWeightWasteDifferencePercent(task) }}
                  WASTE {{ formatWeightWithUnits(getNetWeightOfComponentMeasurementWaste(task)) }}
                </v-chip>
                <v-chip color="yellow" v-if="getTotalWasteCost(task)">
                  {{ formatCurrency(getTotalWasteCost(task)) }}
                  COST ({{ formatCurrency(getComponentCostPer100Grams(task)) }}/100g)
                </v-chip>
              </v-chip-group>
            </v-col>

          </v-row>
          <v-alert type="error" v-if="isCostDifferentPerMeal(task)">
            WARNING: component has different cost for each meal
            <br/>
            {{ getCosts(task).join(' ') }}
          </v-alert>
        </v-card-text>
        <v-card-text class="elevation-4 mt-2">
          <!--          <pre>{{ task.source.meals.map(m => m.measurements) }}</pre>-->
          <div v-for="(meal,index) of getMeals(task)" v-bind:key="`MC${index}`">
            <v-row
              v-if="showAllergy && meal.totalAmountAllergy"
              dense no-gutters class="pt-4">
              <v-col>Allergies:
                <v-chip x-small v-for="a of meal.activeAllergy" v-bind:key="a" class="mr-1">
                  {{ getRestrictionText(a) }}
                </v-chip>
              </v-col>
              <v-col cols="3" class="text-right">{{ formatWeightWithUnits(meal.totalAmountAllergy) }}</v-col>
            </v-row>
            <v-row
              dense no-gutters
              :class="!(showAllergy && meal.totalAmountAllergy) ? 'pt-4' : ''">
              <v-col>
                {{ getMealDescription(task.description, index) }}
              </v-col>
              <v-col cols="4" class="text-right">
                <!--                {{ formatWeightWithUnits(meal.totalAmount) }}-->
                <template v-if="showAllergy && meal.totalAmountAllergy">
                  <br/>
                  -A {{ formatWeightWithUnits(meal.totalAmount - meal.totalAmountAllergy) }}
                </template>
                <template v-if="!!getMeasurement(meal)">
                  <br/>
                  <v-icon x-small color="green">mdi-scale</v-icon>
                  {{ formatWeightWithUnits(getNetWeightOfMealMeasurement(meal)) }}
                  <br/>

                  <v-chip color="red" outlined v-if="getWeightDifferenceMeal(meal)<0" class="mb-2">
                    {{ getMealWeightDifferencePercent(meal) }} SHORT
                    {{ formatWeightWithUnits(Math.abs(getWeightDifferenceMeal(meal))) }}
                  </v-chip>
                  <v-chip color="blue" outlined v-if="isOverWeightMeal(meal)" class="mb-2">
                    {{ getMealWeightDifferencePercent(meal) }} OVER
                    {{ formatWeightWithUnits(Math.abs(getWeightDifferenceMeal(meal))) }}
                  </v-chip>
                  <v-chip color="green" outlined v-if="isWeightOkMeal(meal)" class="mb-2">OK</v-chip>
                </template>
                <template v-if="getNetWeightOfMealWasteMeasurement(meal)>0">
                  <br/>
                  <v-chip color="yellow" v-if="getNetWeightOfMealWasteMeasurement(meal)>0">
                    {{ getMealWeightWasteDifferencePercent(meal) }}
                    WASTE
                    {{ formatWeightWithUnits(getNetWeightOfMealWasteMeasurement(meal)) }}
                  </v-chip>
                </template>
              </v-col>
            </v-row>

          </div>
        </v-card-text>
      </template>
      <template v-slot:task-actions>
        <span></span>
      </template>
    </TaskPanel>
    <v-alert v-if="!hasDates">
      Please select a production date
    </v-alert>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {dateFormatted, formatCurrency, formatWeight, getDatesInRange, getProductionDays} from '@/store/utils';
import TaskPanel from '@/components/tasks/TaskPanel';
import moment from 'moment';
import ComponentWeighTask from "@/components/tasks/ComponentWeighTask";
import api from "@/api";
import urlState from "@/router/urlState";

import diff from 'microdiff';

export default {
  name: "ComponentWeighTasks",
  components: {ComponentWeighTask, TaskPanel},
  mixins: [urlState],
  destroyed() {
    document.removeEventListener('keydown', this.keyListener, true);
  },

  mounted() {
    document.addEventListener('keydown', this.keyListener, true);
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'searchInput', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'taskFilter', urlParam: 'status', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showAllergy', urlParam: 'allergy', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'showGarnish', urlParam: 'garnish', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });

    return Promise.all([
      this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo)),
      this.fetchRestrictions()
    ]);
  },
  watch: {
    searchInput(v) {
      console.log('search input', v);
      const [firstPart, line, mealId] = (v || '').split(':');
      this.search = firstPart;
      this.line = line;
      this.mealId = mealId;
      if (this.scannerMode && !this.showDialog && this.automaticShow && this.tasksUpdated[0]) {
        this.cardClickedHandler(this.tasksUpdated[0]);
      }
    },
    dateFrom() {
      if (!this.isDataLoaded) {
        this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo));
      }
    },
    dateTo() {
      if (!this.isDataLoaded) {
        this.fetchTasks(getDatesInRange(this.dateFrom, this.dateTo));
      }
    }
  },
  computed: {
    ...mapGetters(['getComponent', 'getMeal', 'getRestrictionText']),
    ...mapState(['containers']),
    filter() {
      return this.showGarnish ? '' : 'garnish';
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    hasDates() {
      return this.dates.length > 0;
    },
    dataAsTable() {
      const rows = [];

      if (this.loading) {
        return rows;
      }

      function formatNumber(v) {

        if (v === undefined || v === null) {
          return '';
        } else {
          v = Math.round(v);
          return v.toLocaleString();
        }
      }

      const tasksWithoutSource = []
      for (const task of this.tasksFiltered) {
        const {title, description} = task;
        // console.log('task', task);
        const componentMeasuredWeight = this.getNetWeightOfComponentMeasurement(task);
        if (!task.source) {
          // console.warn('no source for task', task);
          tasksWithoutSource.push(task);
        } else {
          const teamMembers = [...new Set((task.execution || []).flatMap(e => e.team_members.map(m => m.name)))].join(', ');
          let qa = ''
          if (task.source.qa) {
            qa = task.source.qa.passed ? 'pass' : task.source.qa.failureReason
          }

          const addMealRow = (meal, index) => {
            const {meal_id, component_id, totalAmount} = meal;
            const name = this.getMealDescription(description, index);
            const mealMeasuredWeight = this.getNetWeightOfMealMeasurement({meal_id, component_id})
            // console.log('meal', meal);
            // const componentRequiredWeight = this.getTotalAmount(task);
            const mealWeightDifference = mealMeasuredWeight - totalAmount;
            const componentStatus = (this.isWeightOkTask(task) && 'ok')
              || (this.isShortWeightTask(task) && 'short')
              || (this.isOverWeightTask(task) && 'over')
              || '';

            const mealStatus = !componentStatus
              ? ''
              : (
                (this.isWeightOkMeal(meal) && 'ok')
                || (this.isShortWeightMeal(meal) && 'short')
                || (this.isOverWeightMeal(meal) && 'over'))
              || '';
            // const netWeightOfMealWasteMeasurement = this.getNetWeightOfComponentMeasurementWaste(task) > 0
            //   ? formatNumber(this.getNetWeightOfComponentMeasurementWaste(task))
            //   : '';
            //
            // const costPer100g = this.getComponentCostPer100Grams(task) ? formatCurrency(this.getComponentCostPer100Grams(task)) : '';

            // const costTarget = costPer100g ? formatCurrency(this.getTargetComponentCost(task)) : '';
            // const costActual = (costPer100g && componentMeasuredWeight) ? formatCurrency(this.getActualComponentCost(task)) : '';
            // const wasteCost = (costPer100g && netWeightOfMealWasteMeasurement) ? formatCurrency(this.getTotalWasteCost(task)) : '';
            // const costDifference = costActual ? formatCurrency(this.getActualComponentCost(task) - this.getTargetComponentCost(task)) : '';
            rows.push({
              component: title,
              meal: name,
              // componentRequiredWeight: formatNumber(componentRequiredWeight),
              // componentMeasuredWeight: componentStatus ? formatNumber(componentMeasuredWeight) : '',
              // componentWeightDifference:
              //   componentStatus
              //     ? formatNumber(componentMeasuredWeight - componentRequiredWeight)
              //     : '',
              // componentWeightDifferencePercent: this.getWeightDifferencePercent(task),
              // componentWaste: netWeightOfMealWasteMeasurement,
              // componentWastePercent: netWeightOfMealWasteMeasurement ? this.getWeightWasteDifferencePercent(task) : '',
              componentStatus,
              mealRequiredWeight: formatNumber(totalAmount),
              mealMeasuredWeight: mealStatus ? formatNumber(mealMeasuredWeight) : '',
              mealWeightDifference: mealStatus
                ? formatNumber(mealWeightDifference)
                : '',
              mealWeightDifferencePercent: `${Math.round((mealWeightDifference / totalAmount) * 100)}%`,
              mealStatus,
              teamMembers,
              // qa,
              // costPer100g,
              // costDifferent: task.source && task.source.costsDifferent,
              // costTarget,
              // costActual,
              // costDifference,
              // wasteCost,
            });
          }
          const addComponentRow = () => {
            // console.log('meal', meal);
            const componentRequiredWeight = this.getTotalAmount(task);
            const componentStatus = (this.isWeightOkTask(task) && 'ok')
              || (this.isShortWeightTask(task) && 'short')
              || (this.isOverWeightTask(task) && 'over')
              || '';
            const netWeightOfMealWasteMeasurement = this.getNetWeightOfComponentMeasurementWaste(task) > 0
              ? formatNumber(this.getNetWeightOfComponentMeasurementWaste(task))
              : '';
            const costPer100g = this.getComponentCostPer100Grams(task) ? formatCurrency(this.getComponentCostPer100Grams(task)) : '';
            const costTarget = costPer100g ? formatCurrency(this.getTargetComponentCost(task)) : '';
            const costActual = (costPer100g && componentMeasuredWeight) ? formatCurrency(this.getActualComponentCost(task)) : '';
            const wasteCost = (costPer100g && netWeightOfMealWasteMeasurement) ? formatCurrency(this.getTotalWasteCost(task)) : '';
            const costDifference = costActual ? formatCurrency(this.getActualComponentCost(task) - this.getTargetComponentCost(task)) : '';
            rows.push({
              component: title,
              componentRequiredWeight: formatNumber(componentRequiredWeight),
              componentMeasuredWeight: componentStatus ? formatNumber(componentMeasuredWeight) : '',
              componentWeightDifference:
                componentStatus
                  ? formatNumber(componentMeasuredWeight - componentRequiredWeight)
                  : '',
              componentWeightDifferencePercent: this.getWeightDifferencePercent(task),
              componentWaste: netWeightOfMealWasteMeasurement,
              componentWastePercent: netWeightOfMealWasteMeasurement ? this.getWeightWasteDifferencePercent(task) : '',
              componentStatus,
              teamMembers,
              qa,
              costPer100g,
              costDifferent: task.source && task.source.costsDifferent,
              costTarget,
              costActual,
              costDifference,
              wasteCost,
            });
          }
          if (this.exportMealTasks) {
            task.source.meals.forEach(addMealRow);
          } else {
            addComponentRow();
          }
        }
      }
      // console.log('rows', rows);
      if (tasksWithoutSource.length > 0) {
        console.warn('no source for tasks', tasksWithoutSource);
      }
      return rows;
    },
    tasks() {
      let tasks = this.tasksUnfiltered;
      let filterTerms = (this.filter || '').split(' ').filter(s => !!s).map(s => s.trim().toLowerCase());

      function containsKey(t, search) {
        return t.title.toLowerCase().indexOf(search) > -1 || t.description.toLowerCase().indexOf(search) > -1;
      }

      if (filterTerms.length > 0) {
        tasks = tasks.filter(t => !filterTerms.some(term => containsKey(t, term)));
      }
      return tasks;
    },
    tasksWithoutMeasurements() {
      return this.tasks
        .filter(task => task.source && task.source.meals.every(meal => !this.getMeasurement(meal))
        )
    },
    tasksWithMeasurements() {
      return this.tasks
        .filter(task => task.source && task.source.meals.some(meal => !!this.getMeasurement(meal)));
    },
    tasksShort() {
      return this.tasks
        .filter(task => this.isShortWeightTask(task));
    },
    tasksOver() {
      return this.tasks
        .filter(task => this.isOverWeightTask(task));
    },
    tasksWithQaFail() {
      return this.tasks
        .filter(task => task.source && task.source.qa && task.source.qa.failureReason);
    },
    tasksWithoutQA() {
      return this.tasks
        .filter(task => task.source && !task.source.qa);
    },
    tasksWithWaste() {
      return this.tasks
        .filter(task => this.getNetWeightOfComponentMeasurementWaste(task) > 0);
    },
    tasksOk() {
      return this.tasks
        .filter(task => this.isWeightOkTask(task));
    },
    tasksFiltered() {
      const map = {
        all: this.tasks,
        'no-weight': this.tasksWithoutMeasurements,
        'has-weight': this.tasksWithMeasurements,
        short: this.tasksShort,
        over: this.tasksOver,
        ok: this.tasksOk,
        'qa-fail': this.tasksWithQaFail,
        'qa-missing': this.tasksWithoutQA,
        'has-waste': this.tasksWithWaste,
      }
      const mapping = map[this.taskFilter];
      if (mapping) {
        return mapping;
      }
      console.warn('could not find task filter mapping', this.taskFilter);
      return this.tasks;
    },
    activeContainers() {
      const countMap = this.countContainersUsedInMeasurement(this.componentMeasurements, 'file');
      return this.containers
        .filter(c => countMap[c.id] !== undefined)
        .map(c => ({...c, count: countMap[c.id]}));
    },
    activeContainersWaste() {
      console.log('this.componentWasteMeasurements', this.componentWasteMeasurements);
      const countMap = this.countContainersUsedInMeasurement(this.componentWasteMeasurements, 'waste');
      return this.containers
        .filter(c => countMap[c.id] !== undefined)
        .map(c => ({...c, count: countMap[c.id]}));
    },
    isDataLoaded() {
      return JSON.stringify(this.currentDates) === JSON.stringify([this.dateFrom, this.dateTo]);
    }
  },
  methods: {
    ...
      mapActions(['fetchContainers']),
    async keyListener(e) {
      if (!this.scannerMode) {
        return;
      }
      const key = e.key;
      // console.log('keydown', key, this.buffer);
      if (this.showWeighDialog) {
        // no scan while dialog open
        // console.log('no scan while open', key)
        return;
      }
      let regex = /[A-Za-z0-9:-]/g;
      if (key === 'Enter' && this.buffer.length > 0) {
        const newItem = `${this.buffer}`;
        this.buffer = '';
        this.searchInput = newItem;
      }
      if (key.length !== 1 || !regex.test(`${key}`)) {
        // console.log('ignoring input', key)
      } else {
        this.buffer += key;
      }
    }
    ,
    formatCurrency,
    ...
      mapActions(['fetchRestrictions']),
    getDatesInRange,
    getComponents(componentIds) {
      return componentIds.map(this.getComponent).sort((a, b) => a.name.localeCompare(b.name));
    }
    ,
    getTimerId(id) {
      return `I${id}`;
    }
    ,
    dateFormatted,
    closeDatePicker() {
      this.showDatePicker = false;
      const date = moment(this.datePickerDate);
      const dates = getProductionDays(date);
      console.log('setting dates', dates);
      this.dates = dates;
      return this.fetchData();
    }
    ,
    fetchData() {
      this.error = this.post = null
      this.loading = true
    }
    ,
    formatWeightWithUnits(amount) {
      const units = ['g', 'kg'];
      const decimalPlaces = [0, 2];
      return formatWeight(amount, units, decimalPlaces);
    }
    ,
    allowedDates(val) {
      const notAllowed = {
        0: true,
        // 2: true,
        // 4: true
      };

      return !notAllowed[moment(val).day()];
    }
    ,
    cardClickedHandler(task) {
      // task = deepCopy(task);
      if (!task.source) {
        alert('Something fishy about this one.  Please let tom know');
      } else {
        task.source.meals.forEach(meal => {
          const {total_weight, container_id} = this.getMeasurement(meal) || {};
          meal.totalWeight = total_weight;
          meal.containerId = container_id;
        })
        this.selectedTask = task
        this.showWeighDialog = true;
      }
    }
    ,
    // async removeWeight({task, removeMeasurement}) {
    //   console.log('remove ', {task, removeMeasurement});
    //   const {component_id, meal_id, from, to, component_measurement_detail} = removeMeasurement;
    //   for (const {id} of component_measurement_detail) {
    //     const response = await api.delete(`/v2/component/measurement/${id}`);
    //     console.log('delete response', response);
    //   }
    //   // const meal = task.source.meals.find(m => m.meal_id === meal_id);
    //   // meal.measurements.forEach(m => m.component_measurement_detail = m.component_measurement_detail.filter(d => d.id !== id));
    //
    //   const params = {from, to};
    //   const response = await api.get(`/v2/component/${component_id}/measurement/${meal_id}`, {params});
    //   console.log('response', response);
    //   const meal = task.source.meals.find(m => m.meal_id === meal_id);
    //   console.log('measurements before', meal.measurements);
    //   this.$set(meal, 'measurements', response.data);
    //   console.log('measurements after', meal.measurements);
    //   this.taskUpdated(task);
    // },
    async saveQA({task, qa}) {
      console.log('saving qa ', task, qa);
      const component_id = task.source && task.source.component && task.source.component.id;
      const meal_ids = (task.source && task.source.meals && task.source.meals.map(m => m.meal_id)) || [];
      const payload = {...qa, component_id, meal_ids, from: this.dateFrom, to: this.dateTo};
      const response = await api.post(`/v2/component/${component_id}/qa/`, payload);
      this.$set(task.source, 'qa', qa);
      console.log('response', response);
    }
    ,
    async saveWeight({task, mealUpdate, event = 'file'}) {
      console.log('saving ', task, mealUpdate);
      const {component_id, meal_id, component_measurement: {component_measurement_detail}} = mealUpdate;
      const payload = {
        // team_member_id,
        // totalWeight: Number.parseInt(totalWeight),
        // containerId: containerId,
        from: this.dateFrom,
        to: this.dateTo,
        component_measurement_detail: component_measurement_detail
          // .filter(cm => !cm.id)  //remove any that have an id already (those are already saved)
          .map(cm => ({
            container_id: cm.container_id,
            total_weight: Number.parseInt(cm.total_weight),
            note: cm.note
          })),
        event
      };

      const response = await api.post(`/v2/component/${component_id}/measurement/${meal_id}`, payload);
      const [updatedMeasurement] = response.data;
      console.log('response', updatedMeasurement);
      const meal = task.source.meals.find(m => m.meal_id === meal_id);

      if (updatedMeasurement.event === 'file') {
        console.log('measurements before', meal.measurements);
        this.$set(meal, 'measurements', [updatedMeasurement]);
        console.log('measurements after', meal.measurements);
      }
      if (updatedMeasurement.event === 'waste') {
        console.log('measurements before', meal.measurements_waste);
        this.$set(meal, 'measurements_waste', [updatedMeasurement]);
        console.log('measurements after', meal.measurements_waste);
      }

      const {component} = task.source || {};
      console.log('componentMeasurements[component.id], meal.meal_id,', this.componentMeasurements[component.id][meal.meal_id])

      this.taskUpdated(task);

      console.log('componentMeasurements[component.id], meal.meal_id,', this.componentMeasurements[component.id][meal.meal_id])
    }
    ,
    taskUpdated(task) {
      const componentMeasurements = this.componentMeasurements;
      const componentWasteMeasurements = this.componentWasteMeasurements;
      const {component} = task.source || {};
      if (component) {
        // update weight measurement
        this.$set(componentMeasurements, component.id, componentMeasurements[component.id] || {})
        for (const meal of task.source.meals) {
          const measurements = (meal.measurements || [])
            // .filter(m => m.event === 'file')
            .filter(m => m.component_measurement_detail.length > 0)
            .sort((a, b) => b.date.localeCompare(a.date));

          let measurement;
          if (measurements.length > 0) {
            // console.log('measurements for meal', measurements);
            measurement = measurements[0];
            // const detail = measurements.flatMap(m => m.component_measurement_detail);
            // measurement.component_measurement_detail = detail;
          }
          this.$set(componentMeasurements[component.id], meal.meal_id, measurement);

          // update waste measurements
          this.$set(componentWasteMeasurements, component.id, componentWasteMeasurements[component.id] || {})
          // console.log('meal.measurements_waste ', meal.measurements_waste);
          const measurements_waste = (meal.measurements_waste || [])
            // .filter(m => m.event === 'file')
            .filter(m => m.component_measurement_detail.length > 0)
            .sort((a, b) => b.date.localeCompare(a.date));

          let measurement_waste;
          if (measurements_waste.length > 0) {
            // console.log('measurements for meal', measurements);
            measurement_waste = measurements_waste[0];
            // const detail = measurements.flatMap(m => m.component_measurement_detail);
            // measurement.component_measurement_detail = detail;
          }
          this.$set(componentWasteMeasurements[component.id], meal.meal_id, measurement_waste);

        }
      }
    }
    ,
    getMeasurement({meal_id, component_id}) {
      const componentMeasurement = this.componentMeasurements[component_id];
      return componentMeasurement && componentMeasurement[meal_id];
    }
    ,
    getNetWeightOfMealMeasurement({meal_id, component_id}) {
      const componentMeasurement = this.componentMeasurements[component_id];
      // console.log('componentMeasurement && componentMeasurement[meal_id]', componentMeasurement && componentMeasurement[meal_id]);
      const component_measurement_detail = componentMeasurement && componentMeasurement[meal_id] &&
        componentMeasurement[meal_id].component_measurement_detail;

      return component_measurement_detail
        ? component_measurement_detail.reduce((sum, m) => m.net_weight + sum, 0)
        : 0;
    }
    ,
    getNetWeightOfMealWasteMeasurement({meal_id, component_id}) {
      const componentMeasurement = this.componentWasteMeasurements[component_id];
      // console.log('componentMeasurement && componentMeasurement[meal_id]', componentMeasurement && componentMeasurement[meal_id]);
      const component_measurement_detail = componentMeasurement && componentMeasurement[meal_id] &&
        componentMeasurement[meal_id].component_measurement_detail;

      return component_measurement_detail
        ? component_measurement_detail.reduce((sum, m) => m.net_weight + sum, 0)
        : 0;
    }
    ,
    getNetWeightOfComponentMeasurement(task) {
      return this.getMeals(task)
        .reduce((sum, meal) => sum + this.getNetWeightOfMealMeasurement(meal), 0);
    }
    ,
    getNetWeightOfComponentMeasurementWaste(task) {
      return this.getMeals(task)
        .reduce((sum, meal) => sum + this.getNetWeightOfMealWasteMeasurement(meal), 0);
    }
    ,
    isCostDifferentPerMeal(task) {
      return task.source && task.source.costsDifferent;
    }
    ,
    getComponentCostPer100Grams(task) {
      return task.source && task.source.cost;
    }
    ,
    getTargetComponentCost(task) {
      const cost = this.getComponentCostPer100Grams(task)
      if (cost) {
        const amount = this.getTotalAmount(task);
        return ((cost / 100) * amount);
      }
    }
    ,
    getActualComponentCost(task) {
      const cost = this.getComponentCostPer100Grams(task)
      if (cost) {
        const amount = this.getNetWeightOfComponentMeasurement(task);
        return ((cost / 100) * amount);
      }
    }
    ,
    getTotalWasteCost(task) {
      const cost = this.getComponentCostPer100Grams(task)
      if (cost) {
        return ((cost / 100) * this.getNetWeightOfComponentMeasurementWaste(task));
      }
    }
    ,
    getMeals(task) {
      return (task.source && task.source.meals) || [];
    }
    ,
    showCount(tasks) {
      if (tasks && tasks.length > 0) {
        return `(${tasks.length})`;
      }
    }
    ,
    getTotalAmount(task) {
      const amount = (task.source && task.source.totalAmount) || 0;
      const allergyAmount = (task.source && task.source.totalAmountAllergy) || 0;
      return amount - allergyAmount;
    }
    ,
    getWeightDifference(task) {
      const netWeightOfComponentMeasurement = this.getNetWeightOfComponentMeasurement(task);
      if (netWeightOfComponentMeasurement > 0) {
        return netWeightOfComponentMeasurement - this.getTotalAmount(task);
      } else {
        return 0;
      }
    }
    ,
    getWeightDifferencePercent(task) {
      const difference = this.getWeightDifference(task);
      return `${Math.round((difference / this.getTotalAmount(task)) * 100)}%`;
    }
    ,
    getWeightWasteDifferencePercent(task) {
      const difference = this.getNetWeightOfComponentMeasurementWaste(task);
      // console.log('diff', difference);
      return `${Math.round((difference / this.getTotalAmount(task)) * 100)}%`;
    }
    ,
    isWeightOkTask(task) {
      return this.getNetWeightOfComponentMeasurement(task) > 0
        && !(this.isShortWeightTask(task) || this.isOverWeightTask(task));
    }
    ,
    isShortWeightTask(task) {
      const weightDifference = this.getWeightDifference(task);
      return weightDifference < 0
    }
    ,
    isOverWeightTask(task) {
      const percentDifference = this.getWeightDifference(task) / this.getTotalAmount(task);
      return ((percentDifference > .1)
        || this.getWeightDifference(task) > 1000
      );
    }
    ,
    isWeightOkMeal(meal) {
      return !(this.isShortWeightMeal(meal) || this.isOverWeightMeal(meal));
    }
    ,
    isShortWeightMeal(meal) {
      const weightDifferenceMeal = this.getWeightDifferenceMeal(meal);
      return weightDifferenceMeal < 0
    }
    ,
    isOverWeightMeal(meal) {
      const percentDifference = this.getWeightDifferenceMeal(meal) / meal.totalAmount;
      return (percentDifference > .1);
    }
    ,
    getWeightDifferenceMeal(meal) {
      return this.getNetWeightOfMealMeasurement(meal) - (meal.totalAmount - meal.totalAmountAllergy);
    }
    ,
    getMealWeightDifferencePercent(meal) {
      const percent = (this.getWeightDifferenceMeal(meal) / (meal.totalAmount - meal.totalAmountAllergy)) * 100;
      return `${Math.round(percent)}%`
    }
    ,
    getMealWeightWasteDifferencePercent(meal) {
      const percent = (this.getNetWeightOfMealWasteMeasurement(meal) / (meal.totalAmount - meal.totalAmountAllergy)) * 100;
      return `${Math.round(percent)}%`
    }
    ,
    getMealDescription(str, index) {
      return str.split('\n')[index];
    }
    ,
    fetchTasks(dates) {
      if (dates.length === 0) {
        console.log('no dates, cannot fetch tasks yet');
        return;
      }

      this.loading = true;
      this.loadingTasks++;

      this.currentDates = [this.dateFrom, this.dateTo];
      console.log('tasks:fetch', this.loadingTasks, {dates, team: this.team});
      return new Promise(resolve => {
        this.$socket.emit('tasks:fetch',
          {dates, team: this.team, includeCosts: true, forceRegenerate: true},
          (result) => {
            let {error, tasks} = result;
            if (error) {
              alert(`fetch tasks ${dates} failed: ${error}`);
            } else {
              tasks = tasks.sort((a, b) => a.id - b.id);
              console.log(`tasks:fetch ${this.team} - ${dates} result`, tasks);
              const taskMap = {};
              this.tasksUnfiltered = [];
              tasks.forEach(task => {
                this.taskUpdated(task);
                if (taskMap[task.id]) {
                  const difference = diff(taskMap[task.id], task);
                  if (difference.length === 0) {
                    // console.log('same');
                  } else {
                    // console.log('different', difference);
                  }
                  taskMap[task.id] = {...taskMap[task.id], ...task};
                } else {
                  taskMap[task.id] = task;
                }
              });
              this.tasksUnfiltered = Object.values(taskMap);
            }
            this.loadingTasks--;
            this.loading = false;
            resolve();
          }
        );
      });
    }
    ,
    getComponentId(task) {
      return task.source.component.id;
    }
    ,
    getActiveAllergiesInMeals(task) {
      return [...this.getMeals(task)
        .reduce((set, meal) => {
          meal.activeAllergy.forEach(a => set.add(a));
          return set;
        }, new Set())];
    }
    ,
    getTotalAmountofAllergiesInMeals(task) {
      return (task.source && task.source.totalAmountAllergy) || 0;
    }
    ,
    getCosts(task) {
      return task.source && task.source.costs && Object.entries(task.source.costs).map(([mealId, cost]) => `M${mealId}: ${formatCurrency(cost)}`);
    }
    ,
    updateTasks(tasks) {
      if (this.automaticShow) {
        if (tasks.length === 1) {
          this.cardClickedHandler(tasks[0]);
        }
      }
      this.tasksUpdated = tasks;
    }
    ,
    closeDialog() {
      this.showWeighDialog = false;
      this.mealId = '';
      this.line = '';
      // this.search='';
      // this.searchInput='';
    },
    countContainersUsedInMeasurement(measurements, event = false) {
      const containerIdsUsed = Object
        .values(measurements)
        .flatMap(o => Object
          .values(o)
          .filter(cm => cm && (!event || cm.event === event))
          .flatMap(o => o && o.component_measurement_detail && o.component_measurement_detail
            .flatMap(cmd => cmd.container_id)));
      return containerIdsUsed
        .reduce((countMap, containerId) => {
          countMap[containerId] = countMap[containerId] || 0;
          countMap[containerId]++;
          return countMap;
        }, {});
    }
  },
  data() {
    return {
      dates: [],
      search: null,
      teamsSelected: null,
      showDatePicker: null,
      loading: null,
      respondToRouteChanges: true,
      setRouteTimeout: null,
      datePickerDate: null,
      showDialog: null,
      showWeighDialog: null,
      selectedTask: null,
      componentMeasurements: {},
      componentWasteMeasurements: {},
      selectedMeasurement: null,
      taskFilter: null,
      loadingTasks: 0,
      tasksUnfiltered: [],
      showAllergy: false,
      showGarnish: null,
      exportMealTasks: true,
      searchInput: null,
      buffer: '',
      automaticShow: true,
      scannerMode: true,
      line: null,
      mealId: null,
      tasksUpdated: [],  // kind of gross, this is the taskpanel state of tasks (so with search applied)
      showContainerStatDialog: null,
      currentDates: null
    }
  },
  props: {
    team: {
      type: String, required:
        true
    },
    teamFilter: {
      type: Array,
      default:
        null, required:
        false
    }
  }
}
</script>

<style scoped>

</style>
